export function arrToObjBy(arr: any[], key: string): { [key: string]: any } {
  return arr.reduce((r, x) => {
    r[x[key]] = x
    return r
  }, {})
}

export function groupBy(arr: any[], key): { [key: string]: any[] } {
  return arr.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

/**
 * convert object to array (keys -> .id)
 */
export function objToArr(obj, field = 'id') {
  return Object.entries(obj).map(x => {
    // @ts-ignore
    x[1][field] = x[0]
    return x[1]
  })
}
