import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { BookkeepingService } from '../../services/bookkeeping.service'
import { IBookkeepingItem } from '../../shared/types'
import { TranslateService } from '@ngx-translate/core'
import { NzMessageService } from 'ng-zorro-antd'

@Component({
  selector: 'app-bookkeeping',
  templateUrl: './bookkeeping.component.html',
  styleUrls: ['./bookkeeping.component.scss']
})
export class BookkeepingComponent implements OnInit, OnDestroy {

  private onDestroy = new Subject()

  data: IBookkeepingItem[]

  showTable = true

  constructor(
    private bookkeepingService: BookkeepingService,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: NzMessageService
  ) {}

  ngOnInit() {
    // Refresh on lang switch
    this.translate.onLangChange
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.showTable = false
        this.changeDetectorRef.detectChanges()
        this.showTable = true
      })

    this.loadData()
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  sendZipByEmail(id: string): void {
    this.bookkeepingService.sendZipByEmail(id)
      .subscribe(() => {
        this.messageService.success('OK')
      })
  }

  private loadData(): void {
    this.bookkeepingService.getLinks()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.data = data.map(x => {
          x.date = new Date("2000-01-01")
          x.date.setMonth(+x.month - 1)
          x.date.setFullYear(+x.year)
          return x
        })
      })
  }
}
