import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticationService } from './services/authentication.service'
import { IUser, IUserInfo } from './shared/types'
import { TranslateService } from '@ngx-translate/core'
import { LangProvider } from './helpers/lang.provider'
import { WebSocketService } from './services/web-socket.service'
import { version as appVersion } from '../../package.json'

const DEFAULT_LANG = 'en'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public static defaultUrl = '/cashiers'

  appVersion = appVersion

  title = 'quark'

  user: IUser
  userInfo: IUserInfo

  langs = [
    'en',
    'pl'
  ]

  constructor(
    public router: Router,
    private authService: AuthenticationService,
    private webSocketService: WebSocketService,
    private langProvider: LangProvider,
    public translate: TranslateService
  ) {

    this.authService.currentUser.subscribe(user => {
      if (!this.user && user) {
        this.webSocketService.connect()
      } else if (this.user && !user) {
        this.webSocketService.close()
      }

      this.user = user
      if (user) {
        this.userInfo = user.userInfo
      }
    })

    this.webSocketService.wsMessages.subscribe(msg => {
      if (msg) {
        const { type, data } = msg
        if (type === 'order' && data === 'logout') {
          this.logout()
        }
      }
    })

    this.initLangData()
  }

  get isWsConnected() {
    return this.webSocketService.isWsConnected
  }

  get limitsString() {
    if (this.userInfo.limits) {
      return Object.entries(this.userInfo.limits)
        .map(([currency, value]) => {
          return `${value.toLocaleString()} ${currency}`
        })
        .join(' / ')
    } else {
      return '--'
    }
  }

  logout() {
    this.authService.logout()
      .subscribe(() => {
        this.router.navigate(['/login'])
      })
  }

  setLang(lang: string): void {
    localStorage.setItem('lang', lang)
    this.translate.use(lang)
  }

  private initLangData(): void {
    this.langProvider.getLangData().forEach(x => {
      this.translate.setTranslation(x.lang, x.data)
    })

    const lastLang = localStorage.getItem('lang')
    const browserLang = this.translate.getBrowserLang()
    const lang = this.langs.includes(lastLang) ? lastLang
      : (this.langs.includes(browserLang) ? browserLang : DEFAULT_LANG)
    this.translate.use(lang)
  }

}
