import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators'

const API_URL = environment.API_URL

@Injectable()
export class LangProvider {

  private langData

  constructor(
    private http: HttpClient,
  ) {}

  load(): Promise<any> {
    return this.http.get(API_URL + 'lang.php')
      .pipe(map(data => {
        this.langData = data
        return data
      }))
      .toPromise()
  }

  getLangData(): {lang: string, data: any}[] {
    return Object.entries(this.langData).map(x => {
      return {
        lang: x[0],
        data: x[1]
      }
    })
  }

}

export function langProviderFactory(provider: LangProvider) {
  return () => provider.load()
}
