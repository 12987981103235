import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { BaseService } from './base.service'
import { objToArr } from '../shared/utils'

@Injectable({ providedIn: 'root' })
export class ChartDataService extends BaseService {

  constructor(
    http: HttpClient,
    authService: AuthenticationService
  ) {
    super(http, authService)
  }

  /**
   * API:
   * data for chart, you will also get available period and density ranges.
   * params:
   * - period [1D | 1W | 1M] optional, default 1W (can be more in future)
   * - density [1h | 4h | 1D] optional, default 1D (can be more in future)
   * - average [1 | 0] optional, default 0 (if average=1 you get average values]
   */
  getChartData(period: string, density: string): Observable<any> {
    return this.post('francheeChart.php', {
      period,
      density,
      average: 1
    })
      .pipe(map(data => ({
        data: objToArr(data.data, 'date'),
        totals: data.totals,
        ranges: data.ranges
      })))

  }

}
