import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'

function toggleLoadingScreen(show: boolean): void {
  const el = document.getElementById('loading-screen')
  el.style.display = show ? 'block' : 'none'
  // @ts-ignore
  el.children[0].style.opacity = show ? 1 : 0
}

@Injectable({ providedIn: 'root' })
export class BaseService {

  /**
   * Interceptors
   * Hide loading screen, then:
   * Catch user data and token from Http response (need update after every request)
   */

  responseInterceptor = (response: HttpResponse<any>) => {
    toggleLoadingScreen(false)
    this.authService.updateUserByResponse(response)
    return response.body
  }
  errorInterceptor = (error: HttpErrorResponse) => {
    toggleLoadingScreen(false)
    this.authService.updateUserByResponse(error)
    return of(error.error)
  }

  constructor(
    protected http: HttpClient,
    protected authService: AuthenticationService
  ) {
  }

  /**
   * API GET-request
   */
  protected get(url: string, params = {}, responseType = 'json'): Observable<any> {
    toggleLoadingScreen(true)
    // @ts-ignore
    return this.http.get(environment.API_URL + url, { observe: 'response', params, responseType })
      .pipe(
        map(this.responseInterceptor),
        catchError(this.errorInterceptor)
      )
  }

  /**
   * API POST-request
   */
  protected post(url: string, params = {}, data: any = {}): Observable<any> {
    toggleLoadingScreen(true)
    return this.http.post(environment.API_URL + url, data, { observe: 'response', params })
      .pipe(
        map(this.responseInterceptor),
        catchError(this.errorInterceptor)
      )
  }

  /**
   * API GET-request
   */
  protected crGet(url: string, params = {}): Observable<any> {
    toggleLoadingScreen(true)
    return this.http.get(environment.API_CR_URL + url, {
      observe: 'response',
      params,
      headers: {
        Accept: 'application/vnd.quark.v1+json',
        'Content-Type': 'application/vnd.quark.v1+json'
      }
    })
      .pipe(
        map(this.responseInterceptor),
        catchError(this.errorInterceptor)
      )
  }

  /**
   * API_CR PUT-request
   */
  protected crPut(url: string, params = {}, data: any = {}): Observable<any> {
    toggleLoadingScreen(true)
    return this.http.request('PUT', environment.API_CR_URL + url, {
      body: data,
      observe: 'response',
      params,
      headers: {
        Accept: 'application/vnd.quark.v1+json',
        'Content-Type': 'application/vnd.quark.v1+json'
      }
    })
      .pipe(
        map(this.responseInterceptor),
        catchError(this.errorInterceptor)
      )
  }

  /**
   * API_CR DELETE-request
   */
  protected crDelete(url: string, params = {}): Observable<any> {
    toggleLoadingScreen(true)
    return this.http.request('DELETE', environment.API_CR_URL + url, {
      observe: 'response',
      params,
      headers: {
        Accept: 'application/vnd.quark.v1+json',
        'Content-Type': 'application/vnd.quark.v1+json'
      }
    })
      .pipe(
        map(this.responseInterceptor),
        catchError(this.errorInterceptor)
      )
  }

  protected clearEmptyProps(data: { [k: string]: any }): { [k: string]: any } {
    for (const k in data) {
      if (data.hasOwnProperty(k) && data[k] === '') {
        delete data[k]
      }
    }
    return data
  }
}
