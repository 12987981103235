import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { BaseService } from './base.service'
import { Note } from '../shared/types'

@Injectable({ providedIn: 'root' })
export class NotesService extends BaseService {

  constructor(
    http: HttpClient,
    authService: AuthenticationService
  ) {
    super(http, authService)
  }

  getNotes(): Observable<Note[]> {
    return this.post('francheeListNotes.php')
      .pipe(map((data: any) => data.notes.notes))
  }
}
