import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { BaseService } from './base.service'
import { IBookkeepingItem } from '../shared/types'
import { objToArr } from '../shared/utils'

@Injectable({ providedIn: 'root' })
export class BookkeepingService extends BaseService {

  constructor(
    http: HttpClient,
    authService: AuthenticationService
  ) {
    super(http, authService)
  }

  getLinks(): Observable<IBookkeepingItem[]> {
    return this.post('francheeBookkeeping.php')
      .pipe(map(data => objToArr(data.bookkeeping) as IBookkeepingItem[]))

  }

  /**
   * sends chosen zip-file by email (can take up to 5 min)
   */
  sendZipByEmail(id: string): Observable<any> {
    return this.post('francheeBookkeeping.php', { id })
  }

}
