import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { ICashier, ICashRegister, ICashRegisterData } from '../../../shared/types'
import { takeUntil } from 'rxjs/operators'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { CashierService, ICashierData } from '../../../services/cashier.service'
import { Observable, Subject } from 'rxjs'
import { PercentType } from '../../../shared/enums'
import { CashRegisterService } from '../../../services/cash-register.service'
import { AuthenticationService } from '../../../services/authentication.service'

@Component({
  selector: 'app-cashier-edit',
  templateUrl: './cashier-edit.component.html',
  styleUrls: ['./cashier-edit.component.scss']
})
export class CashierEditComponent implements OnInit, OnDestroy {

  @Output() cashiersChange = new EventEmitter()

  PERCENT_MIN = 0.1

  PercentType = PercentType

  cashierData: ICashier = null

  opened = false

  percentType: PercentType
  percentValue: number
  percentMin: number
  percentMax: number

  percentsError: string = null

  percentValueInputExists = true

  form: FormGroup

  isBoxed = false
  cashRegistersOpts: ICashRegister[] = []
  initialCR: number | null
  selectedCR: number | null

  private onDestroy = new Subject()

  constructor(
    private cashierService: CashierService,
    private crService: CashRegisterService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  onNew(): void {
    this.isBoxed = this.authService.currentUserValue.userInfo.isBoxed

    if (this.isBoxed) {
      this.crService.getAll()
        .pipe(takeUntil(this.onDestroy))
        .subscribe(data => {
          this.cashRegistersOpts = data.filter(cr => cr.status !== 'OPENED')

          this.initialCR = null
          this.selectedCR = this.initialCR

          this.opened = true
          this.cashierData = null
          this.fillForm()
        })
    } else {
      this.initialCR = null
      this.selectedCR = -1

      this.opened = true
      this.cashierData = null
      this.fillForm()
    }
  }

  onEdit(cashier: ICashier): void {
    this.isBoxed = this.authService.currentUserValue.userInfo.isBoxed

    if (this.isBoxed) {
      this.crService.getAll()
        .pipe(takeUntil(this.onDestroy))
        .subscribe(cashRegisters => {
          const cashierCR = cashRegisters.find(cr => cr.authorizedCashiers.includes(+cashier.id))
          this.cashRegistersOpts = cashRegisters.filter(cr => cr.status !== 'OPENED' || cr === cashierCR)

          this.initialCR = cashierCR ? cashierCR.id : null
          this.selectedCR = this.initialCR

          this.opened = true
          this.cashierData = cashier
          this.fillForm()
        })
    } else {
      this.opened = true
      this.cashierData = cashier
      this.fillForm()
    }
  }

  save(): void {
    this.checkPercents()
    if (this.percentsError) {
      return
    }

    const data = this.getFormData()
    if (this.cashierData) {
      this.cashierService.updateOne(this.cashierData.id, data)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(result => {
          this.cashiersChange.emit()
          this.handleSave(result.cashier ? result.cashier : null)
        })

    } else {
      const dataFull = {
        ...data,
        email: this.form.value.email,
        is2FA: this.form.value.is2FA
      }
      this.cashierService.createOne(dataFull)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(result => {
          this.cashiersChange.emit()
          this.handleSave(result.cashier ? result.cashier : null)
        })
    }
  }

  async handleSave(cashier: ICashier | null) {
    if (cashier && this.initialCR !== this.selectedCR) {
      if (this.initialCR !== null) {
        await this.changeCRCashiersList(this.initialCR, +cashier.id, false).toPromise()
      }
      if (this.selectedCR === -1) {
        const newCRData: ICashRegisterData = {
          name: 'Kasa kasjera ' + cashier.nickname,
          authorizedCashiers: [+cashier.id]
        }
        await this.crService.createOne(newCRData).toPromise()
      } else {
        await this.changeCRCashiersList(this.selectedCR, +cashier.id, true).toPromise()
      }
    }
    this.close()
  }

  close(): void {
    this.percentsError = null
    this.opened = false
  }

  fillForm(): void {
    if (this.cashierData) {
      const d = this.cashierData
      this.form = this.formBuilder.group({
        nickname: [d.nickname, Validators.required],
        email: [{ value: d.email, disabled: true }],
      })
      this.percentType = d.percent.type
      this.percentValue = d.percent.value
      this.percentMin = d.percent.min
      this.percentMax = d.percent.max
    } else {
      this.form = this.formBuilder.group({
        nickname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        is2FA: [false]
      })
      this.percentType = PercentType.Fixed
      this.percentValue = null
      this.percentMin = null
      this.percentMax = null
    }
  }

  percentsValid(): boolean {
    return this.percentType === PercentType.Fixed
      ? typeof this.percentValue === 'number'
      : typeof this.percentValue === 'number' && typeof this.percentMin === 'number' && typeof this.percentMax === 'number'
  }

  // percentValueMin(): number {
  //   return typeof this.percentMin === 'number' && this.percentType === PercentType.Free ? this.percentMin : 0
  // }

  // percentValueMax(): number {
  //   return typeof this.percentMax === 'number' && this.percentType === PercentType.Free ? this.percentMax : 100
  // }

  updatePercentValue(): void {
    if (typeof this.percentMin === 'number' && typeof this.percentValue === 'number') {
      this.percentValue = Math.max(this.percentMin, this.percentValue)
    }
    if (typeof this.percentMax === 'number' && typeof this.percentValue === 'number') {
      this.percentValue = Math.min(this.percentMax, this.percentValue)
    }
  }

  onPercentTypeChange(): void {
    this.updatePercentValue()

    // Now min/max margins change.
    // nz-zorro number input compnent can't update it for some reason (bug maybe)
    // So we need to recreate it
    this.percentValueInputExists = false
    setTimeout(() => {
      this.percentValueInputExists = true
    })
  }

  private getFormData(): ICashierData {
    const data: ICashierData = {
      nickname: this.form.value.nickname,
      percentType: this.percentType,
      percent: this.percentValue
    }
    if (this.percentType === PercentType.Free) {
      data.percentMin = this.percentMin
      data.percentMax = this.percentMax
    }
    return data
  }

  private checkPercents(): void {
    const { percentType, percent, percentMin, percentMax } = this.getFormData()

    const PERCENT_MIN = this.PERCENT_MIN

    if (percentType === 'fixed') {
      this.percentsError = PERCENT_MIN <= +percent && +percent <= 100 ? ''
        : `Wrong value. Please follow the rule: "${PERCENT_MIN} ⩽ Percent ⩽ 100"`
    } else {
      this.percentsError = (PERCENT_MIN <= +percentMin && +percentMin <= +percent && percent <= +percentMax && +percentMax <= 100) ? ''
        : `Wrong values in Percent fields. Please follow the rule:\n"${PERCENT_MIN} ⩽ Percent Min ⩽ Percent ⩽ Percent Max ⩽ 100"`
    }
  }

  private changeCRCashiersList(crId: number, cashierId: number, add: boolean): Observable<any> {
    const cashRegister = this.cashRegistersOpts.find(cr => cr.id === crId)
    if (!cashRegister) {
      throw new Error(`Cash registers not loaded yet`)
    }
    const newList = cashRegister.authorizedCashiers.slice()
    if (add) {
      newList.push(cashierId)
    } else {
      newList.splice(newList.indexOf(cashierId), 1)
    }
    return this.crService.updateOne(crId, { name: cashRegister.name, authorizedCashiers: newList })
  }
}
