import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { TransactionService } from '../../../services/transaction.service'
import { ICashReportData, IUserInfo } from '../../../shared/types'
import { AuthenticationService } from '../../../services/authentication.service'

@Component({
  selector: 'app-report-add',
  templateUrl: './report-add.component.html',
  styleUrls: ['./report-add.component.scss']
})
export class ReportAddComponent implements OnInit, OnDestroy {

  @Output() dataChange = new EventEmitter()

  opened = false

  form: FormGroup

  setCurrentDateTime = true

  showTimeError = false

  userInfo: IUserInfo

  private onDestroy = new Subject()

  constructor(
    private transactionService: TransactionService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(user => {
      if (user) {
        this.userInfo = user.userInfo
      }
    })
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  open(): void {
    this.opened = true
    this.fillForm()
  }

  save(): void {
    const date: Date = this.form.value.date
    const time: Date = this.form.value.time
    date.setHours(time.getHours())
    date.setMinutes(time.getMinutes())
    date.setSeconds(0)

    this.showTimeError = date > (new Date())
    if (this.showTimeError) {
      return
    }

    const data: ICashReportData = {
      datetime: Math.floor(date.getTime() / 1000),
      crType: this.form.value.crType,
      fiatAmount: this.form.value.value,
      currency: this.userInfo.fiat,
      docNumber: this.form.value.docNumber,
      description: this.form.value.description
    }

    this.transactionService.addCashReport(data)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.dataChange.emit()
        this.close()
      })

  }

  close(): void {
    this.opened = false
  }

  toggleAutoDate(): void {
    this.setCurrentDateTime = !this.setCurrentDateTime
    if (!this.setCurrentDateTime) {
      this.form.controls.date.setValue(null)
      this.form.controls.time.setValue(null)
    } else {
      const date = new Date()
      const time = new Date()
      time.setSeconds(0)

      this.form.controls.date.setValue(date)
      this.form.controls.time.setValue(time)
    }
  }

  disabledDate(date: Date): boolean {
    return date > (new Date())
  }

  private fillForm(): void {
    this.setCurrentDateTime = true
    this.showTimeError = false

    const date = new Date()
    const time = new Date()
    time.setSeconds(0)

    this.form = this.formBuilder.group({
      date: [date, Validators.required],
      time: [time, Validators.required],
      crType: ['in'],
      value: ['', Validators.required],
      docNumber: [''],
      description: ['']
    })
  }
}
