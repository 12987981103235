import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { IUser, IUserInfo } from '../shared/types'

const API_URL = environment.API_URL

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<IUser>

  public currentUser: Observable<IUser>

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<IUser>(JSON.parse(sessionStorage.getItem('currentUser')))
    this.currentUser = this.currentUserSubject.asObservable()
  }

  public get currentUserValue(): IUser {
    return this.currentUserSubject.value
  }

  /**
   * Update current user
   * Now token and userInfo are actual
   * @param response - response of last request
   * @param isLogin - is last request === login
   */
  public updateUserByResponse(response: HttpResponse<any> | HttpErrorResponse, isLogin: boolean = false): void {
    const token = response.headers.get('auth-token')
    if (!token) {
      return
    }

    if (isLogin) {
      sessionStorage.setItem('loginToken', token)
    }

    let currentUser: any = this.currentUserValue
    if (!currentUser) {
      currentUser = {}
    }

    currentUser.token = token
    if ((response as HttpResponse<any>).body && (response as HttpResponse<any>).body.user) {
      const newUserInfo: IUserInfo = (response as HttpResponse<any>).body.user

      // Fix for backend bug. Sometimes userInfo miss key 'isBoxed'
      if (newUserInfo.isBoxed === undefined && currentUser.userInfo && currentUser.userInfo.isBoxed) {
        newUserInfo.isBoxed = currentUser.userInfo.isBoxed
      }

      currentUser.userInfo = (response as HttpResponse<any>).body.user
    }

    // store user details and token in local storage to keep user logged in between page refreshes
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser))
    this.currentUserSubject.next(currentUser)
  }

  login(email: string, passwd: string, gaKod: string) {
    const formData = new FormData()
    formData.append('email', email)
    formData.append('passwd', passwd)
    if (parseInt(gaKod, 10)) {
      formData.append('gaKod', gaKod)
    }

    return this.http.post<any>(`${API_URL}francheeLogin.php`, formData, { observe: 'response' })
      .pipe(
        map((response: HttpResponse<any>) => {
          this.updateUserByResponse(response, true)
        }))
  }

  logout(): Observable<any> {
    return this.http.post<any>(`${API_URL}francheeLogout.php`, {}, { observe: 'response' })
      .pipe(map(() => {
        this.resetUser()
      }))
  }

  resetUser() {
    sessionStorage.removeItem('currentUser')
    this.currentUserSubject.next(null)
  }
}
