import { BrowserModule } from '@angular/platform-browser'
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { en_GB, NgZorroAntdModule, NZ_I18N, pl_PL } from 'ng-zorro-antd'
import { CashiersComponent } from './pages/cashiers/cashiers.component'
import { ProfileComponent } from './pages/profile/profile.component'
import { LoginComponent } from './pages/login/login.component'
import { TokenInterceptor } from './auth/token.interceptor'
import { ErrorInterceptor } from './helpers/error.interceptor'
import { CashierEditComponent } from './pages/cashiers/cashier-edit/cashier-edit.component'
import { TransactionsComponent } from './pages/transactions/transactions.component'
import { ReportAddComponent } from './pages/transactions/report-add/report-add.component'
import { DepositComponent } from './pages/deposit/deposit.component'
import { BookkeepingComponent } from './pages/bookkeeping/bookkeeping.component'
import { ChartsComponent } from './pages/charts/charts.component'
import { GoogleChartsModule, ɵa } from 'angular-google-charts'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { LangProvider, langProviderFactory } from './helpers/lang.provider'
import pl from '@angular/common/locales/pl'
import { registerLocaleData } from '@angular/common'
import { FranchiseComponent } from './pages/franchise/franchise.component'
import { CashRegistersComponent } from './pages/cash-registers/cash-registers.component'
import { NotesComponent } from './pages/notes/notes.component'
import { VirtualMoneyComponent } from './pages/virtual-money/virtual-money.component'

/************************************* I18n ****************************************/

registerLocaleData(pl)

const LANG_LOCALES = { en: 'en_GB', pl: 'pl' }
const LANG_I18N = { en: en_GB, pl: pl_PL }

export class DynamicLocaleId extends String {
  constructor(private translateService: TranslateService) {
    super('')
  }
  toString() {
    return LANG_LOCALES[this.translateService.currentLang]
  }
}

export class DynamicI18n {
  constructor(private translateService: TranslateService) {
    Object.assign(this, LANG_I18N[this.translateService.currentLang])
    this.translateService.onLangChange.subscribe(x => {
      Object.assign(this, LANG_I18N[x.lang])
    })
  }
}

/************************************* / I18n ****************************************/

@NgModule({
  declarations: [
    AppComponent,

    // Pages
    CashiersComponent,
    ProfileComponent,
    LoginComponent,
    CashierEditComponent,
    TransactionsComponent,
    ReportAddComponent,
    DepositComponent,
    BookkeepingComponent,
    ChartsComponent,
    FranchiseComponent,
    CashRegistersComponent,
    NotesComponent,
    VirtualMoneyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    GoogleChartsModule,
    TranslateModule.forRoot()
  ],
  providers: [
    LangProvider,
    ɵa,
    { provide: APP_INITIALIZER, useFactory: langProviderFactory, deps: [LangProvider], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: NZ_I18N,
      deps: [TranslateService],
      useClass: DynamicI18n
    },
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useClass: DynamicLocaleId
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
