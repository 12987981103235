import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { BaseService } from './base.service'
import { ICashier, ICashReport, ICashReportData, ICashReportsGetParams, ITransaction } from '../shared/types'

@Injectable({ providedIn: 'root' })
export class TransactionService extends BaseService {

  constructor(
    http: HttpClient,
    authService: AuthenticationService
  ) {
    super(http, authService)
  }

  getAll(
    filter: { clients: boolean, franchisor: boolean, depoWithd: boolean },
    dateRange: Date[],
    size: number,
    page: number,
    sortBy: string = null,
    sortDesc: boolean = false
  ): Observable<[ITransaction[], number]> {
    const params: any = {
      clients: filter.clients ? 1 : 0,
      franchisor: filter.franchisor ? 1 : 0,
      depo_withd: filter.depoWithd ? 1 : 0,
      size,
      page,
    }
    if (dateRange.length > 0) {
      params.from = Math.floor(dateRange[0].getTime() / 1000)
      params.to = Math.floor(dateRange[1].getTime() / 1000)
    }
    if (sortBy) {
      params.sortField = sortBy
      params.sortOrder = sortDesc ? 'DESC' : 'ASC'
    }
    return this.post('francheeListTxs.php', params)
      .pipe(map(data => [
        data.txs.length === undefined ? Object.values(data.txs) : data.txs, // because API can return Object instead Array
        data.totalItems
      ]))
  }

  getCashierTransactions(
    id: string,
    finishedOnly: boolean,
    dateRange: Date[],
    size: number,
    page: number,
    sortBy: string = null,
    sortDesc: boolean = false
  ): Observable<[ICashier, ITransaction[], number]> {
    const params: any = {
      id,
      finished: finishedOnly ? 1 : 0,
      size,
      page
    }
    if (dateRange.length > 0) {
      params.from = Math.floor(dateRange[0].getTime() / 1000)
      params.to = Math.floor(dateRange[1].getTime() / 1000)
    }
    if (sortBy) {
      params.sortField = sortBy
      params.sortOrder = sortDesc ? 'DESC' : 'ASC'
    }
    return this.post('francheeCashierListTxs.php', params)
      .pipe(map(data => {
        return [
          data.cashier,
          data.txs.length === undefined ? Object.values(data.txs) : data.txs, // because API can return Object instead Array
          data.totalItems
        ]
      }))
  }

  getCashReports(getParams: ICashReportsGetParams): Observable<[ICashReport[], number]> {
    const params = this.transformCashReportGetParams(getParams)
    return this.post('francheeCR.php', params)
      .pipe(map(data => [
        data.CR,
        data.totalItems
      ]))
  }

  addCashReport(data: ICashReportData): Observable<any> {
    return this.post('francheeCR.php?add', {}, this.clearEmptyProps(data))
  }

  deleteOrRestoreCashReport(isDelete: boolean, id: string, getParams: ICashReportsGetParams): Observable<[ICashReport[], number]> {
    const params = isDelete ? { delete: id } : { undelete: id }
    return this.post('francheeCR.php', { ...params, ...this.transformCashReportGetParams(getParams) })
      .pipe(map(data => [
        data.CR,
        data.totalItems
      ]))
  }

  getReportsPDFs(): Observable<{ date: Date, url: string }[]> {
    return this.get('francheeCRPDF.php')
      .pipe(map(data => {
        return Object.entries(data.CR_PDF).map(x => {
          return {
            date: new Date(x[0]),
            url: x[1] as string
          }
        })
      }))
  }

  private transformCashReportGetParams(getParams: ICashReportsGetParams): any {
    const { active, dateRange, size, page} = getParams
    const result: any = {
      active: active ? 1 : 0,
      size,
      page
    }
    if (dateRange.length > 0) {
      result.from = Math.floor(dateRange[0].getTime() / 1000)
      result.to = Math.floor(dateRange[1].getTime() / 1000)
    }
    return result
  }

}
