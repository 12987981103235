import { Component, OnDestroy, OnInit } from '@angular/core'
import { CashRegisterService } from '../../services/cash-register.service'
import { forkJoin, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ICashRegister, ICashRegisterData, ICashRegisterExt } from '../../shared/types'
import { CashierService } from '../../services/cashier.service'
import { AuthenticationService } from '../../services/authentication.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-cash-registers',
  templateUrl: './cash-registers.component.html',
  styleUrls: ['./cash-registers.component.scss']
})
export class CashRegistersComponent implements OnInit, OnDestroy {

  modalData: ICashRegisterData | null = null
  modalDataId: number | null = null

  loading = true

  dataList: ICashRegisterExt[] = []

  private onDestroy = new Subject()

  constructor(
    private cashRegisterService: CashRegisterService,
    private cashierService: CashierService,
    private authService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
    if (!this.authService.currentUserValue.userInfo.isBoxed) {
      this.router.navigate(['/'])
      return
    }
    this.loadData()
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  loadData(): void {
    this.loading = true

    forkJoin([
      this.cashRegisterService.getAll(),
      this.cashierService.getAllNames()
    ])
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        const [cashRegistersData, cashiersData] = data
        this.dataList = cashRegistersData.map(cr => {
          const cashiers = cashiersData.filter(c => cr.authorizedCashiers.includes(+c.id))
          return { ...cr, cashiers }
        })
        this.loading = false
      })

  }

  addNewCR() {
    this.modalDataId = null
    this.modalData = { name: '', authorizedCashiers: [] }
  }

  editCR(item: ICashRegister) {
    const { name, authorizedCashiers } = item
    this.modalDataId = item.id
    this.modalData = { name, authorizedCashiers }
  }

  deleteCR(item: ICashRegister) {
    this.cashRegisterService.deleteOne(item.id)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.loadData()
      })
  }

  saveModal() {
    if (this.modalDataId === null) {
      this.cashRegisterService.createOne(this.modalData)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => {
          this.modalData = null
          this.loadData()
        })
    } else {
      this.cashRegisterService.updateOne(this.modalDataId, this.modalData)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => {
          this.modalData = null
          this.loadData()
        })
    }
  }
}
