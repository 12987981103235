import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { TransactionService } from '../../services/transaction.service'
import { CashierService } from '../../services/cashier.service'
import { ICashier, ICashReport, ICashReportsGetParams, ITransaction, IUserInfo } from '../../shared/types'
import { takeUntil } from 'rxjs/operators'
import { arrToObjBy } from '../../shared/utils'
import { ReportAddComponent } from './report-add/report-add.component'
import { TranslateService } from '@ngx-translate/core'
import { AuthenticationService } from '../../services/authentication.service'

enum ShowMode {
  All = 'All',
  Cashier = 'Cashier',
  CR = 'CR' // CashReports - (page Cash operations)
}

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit, OnDestroy {

  @ViewChild('reportAddForm', { static: true }) reportAddForm: ReportAddComponent

  loading = true

  ShowMode = ShowMode

  pageIndex = 1
  pageSize = 10
  total = 0
  listOfData: ITransaction[] | ICashReport[] = []

  sortBy = null
  sortDesc: boolean

  dateRange: Date[] = []

  showAll = true

  showMode: ShowMode = null

  // For ShowMode.All
  filter = { clients: true, franchisor: true, depoWithd: true }
  cashiersByIds: { [id: string]: ICashier }

  // For ShowMode.Cashier
  showOnlyFinished = true
  cashierId: string
  cashierData: ICashier

  // For ShowMode.CR
  showOnlyActive = true
  showReportsModal = false
  reportPDFs: { date: Date, url: string }[] = []

  userInfo: IUserInfo

  private onDestroy = new Subject()

  constructor(
    private route: ActivatedRoute,
    private cashierService: CashierService,
    private transactionService: TransactionService,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.authService.currentUser.subscribe(user => {
      this.userInfo = user ? user.userInfo : null
    })

    // Refresh on lang switch
    this.translate.onLangChange
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.showAll = false
        this.changeDetectorRef.detectChanges()
        this.showAll = true
      })

    this.route.params.subscribe(params => {
      if (params.mode === undefined) {
        this.showMode = ShowMode.All
      } else {
        if (params.mode === 'cashier') {
          this.showMode = ShowMode.Cashier
          this.cashierId = params.id
        } else {
          this.showMode = ShowMode.CR
        }
      }
    })

    switch (this.showMode) {
      case ShowMode.All:
        // Need cashiers to show their names
        this.cashierService.getAll(false, 100, 0)
          .pipe(takeUntil(this.onDestroy))
          .subscribe(data => {
            this.cashiersByIds = arrToObjBy(data.list, 'id')
            this.loadData()
          })
        break

      case ShowMode.Cashier:
        this.loadData()
        break

      case ShowMode.CR:
        this.loadData()
        break

      default:
        throw Error('Unexpected mode')
    }

  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  onPageSizeChange(): void {
    // Wait till nzPageIndexChange run, then change pageIndex manually
    setTimeout(() => {
      this.pageIndex = 1
      this.loadData()
    })
  }

  onPageIndexChange(): void {
    // Do nothing, if onPageSizeChange loading data
    setTimeout(() => {
      if (!this.loading) {
        this.loadData()
      }
    })
  }

  setSort(sort: { key: string, value: string }): void {
    this.sortBy = sort.value !== null ? sort.key : null
    this.sortDesc = (sort.value === 'descend')

    this.pageIndex = 1
    this.loadData()
  }

  /**
   * Call it if page index or size changes.
   * Wait till size and page update, so use setTimeout
   */

  loadData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1
    }
    this.loading = true

    switch (this.showMode) {
      case ShowMode.All:
        this.transactionService.getAll(this.filter, this.dateRange, this.pageSize, this.pageIndex - 1, this.sortBy, this.sortDesc)
          .pipe(takeUntil(this.onDestroy))
          .subscribe(data => {
            [this.listOfData, this.total] = data
            this.loading = false
          })
        break

      case ShowMode.Cashier:
        this.transactionService.getCashierTransactions(
          this.cashierId, this.showOnlyFinished, this.dateRange, this.pageSize, this.pageIndex - 1, this.sortBy, this.sortDesc
        )
          .pipe(takeUntil(this.onDestroy))
          .subscribe(data => {
            [this.cashierData, this.listOfData, this.total] = data
            this.loading = false
          })
        break

      case ShowMode.CR:
        const getParams: ICashReportsGetParams = {
          active: this.showOnlyActive,
          dateRange: this.dateRange,
          size: this.pageSize,
          page: this.pageIndex - 1
        }
        this.transactionService.getCashReports(getParams)
          .pipe(takeUntil(this.onDestroy))
          .subscribe(data => {
            [this.listOfData, this.total] = data
            this.loading = false
          })
        break
    }
  }

  deleteOrRestoreCashReport(isDelete: boolean, id: string): void {
    this.loading = true
    const getParams: ICashReportsGetParams = {
      active: this.showOnlyActive,
      dateRange: this.dateRange,
      size: this.pageSize,
      page: this.pageIndex - 1
    }
    this.transactionService.deleteOrRestoreCashReport(isDelete, id, getParams)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        [this.listOfData, this.total] = data
        this.loading = false
      })
  }

  dateRangeChange(): void {
    if (this.dateRange[0] instanceof Date) {
      this.dateRange[0].setHours(0)
      this.dateRange[0].setMinutes(0)
      this.dateRange[0].setSeconds(0)
    }
    if (this.dateRange[1] instanceof Date) {
      this.dateRange[1].setHours(23)
      this.dateRange[1].setMinutes(59)
      this.dateRange[1].setSeconds(59)
    }
    this.loadData(true)
  }

  showReports(): void {
    this.transactionService.getReportsPDFs()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(reports => {
        this.reportPDFs = reports
        this.showReportsModal = true
      })
  }

}
