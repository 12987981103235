import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { FranchiseeService } from '../../services/franchisee.service'
import { takeUntil } from 'rxjs/operators'
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-franchise',
  templateUrl: './franchise.component.html',
  styles: []
})
export class FranchiseComponent implements OnInit, OnDestroy {

  html = null

  private onDestroy = new Subject()

  constructor(
    private franchiseeService: FranchiseeService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.franchiseeService.getHtmlInfo()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(({ body }) => {
        this.html = this.sanitizer.bypassSecurityTrustHtml(body)
      })
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

}
