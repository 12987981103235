import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { VirtualMoneyService } from '../../services/virtual-money.service'
import { Ask } from '../../shared/types'

@Component({
  selector: 'app-virtual-money',
  templateUrl: './virtual-money.component.html',
  styleUrls: ['./virtual-money.component.scss']
})
export class VirtualMoneyComponent implements OnInit, OnDestroy {

  private onDestroy = new Subject()

  data: Ask[]

  askAmount: number | string = ''

  constructor(
    private virtualMoneyService: VirtualMoneyService,
  ) {}

  ngOnInit() {
    this.loadData()
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  public handleAsk(): void {
    this.virtualMoneyService.ask(+this.askAmount)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.data = data
        this.askAmount = ''
      })
  }

  public handleButton(item: Ask): void {
    switch (item.button) {
      case 'settleF':
      case 'delete':
        this.virtualMoneyService.action(item.id, item.button)
          .pipe(takeUntil(this.onDestroy))
          .subscribe(data => {
            this.data = data
          })
        break

      default:
        console.error('Unexpected button: ', item.button)
    }
  }

  private loadData(): void {
    this.virtualMoneyService.getData()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.data = data
      })
  }
}
