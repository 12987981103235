import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CashiersComponent } from './pages/cashiers/cashiers.component'
import { ProfileComponent } from './pages/profile/profile.component'
import { AuthGuard } from './auth/auth.guard'
import { LoginComponent } from './pages/login/login.component'
import { TransactionsComponent } from './pages/transactions/transactions.component'
import { DepositComponent } from './pages/deposit/deposit.component'
import { BookkeepingComponent } from './pages/bookkeeping/bookkeeping.component'
import { ChartsComponent } from './pages/charts/charts.component'
import { AppComponent } from './app.component'
import { FranchiseComponent } from './pages/franchise/franchise.component'
import { CashRegistersComponent } from './pages/cash-registers/cash-registers.component'
import { NotesComponent } from './pages/notes/notes.component'
import { VirtualMoneyComponent } from './pages/virtual-money/virtual-money.component'

const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'cashiers',
    component: CashiersComponent,
    canActivate: [AuthGuard]
  },
  { canActivate: [AuthGuard], component: TransactionsComponent, path: 'transactions/:mode/:id', },
  { canActivate: [AuthGuard], component: TransactionsComponent, path: 'transactions/:mode', },
  { canActivate: [AuthGuard], component: TransactionsComponent, path: 'transactions', },
  {
    path: 'deposit',
    component: DepositComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cash-registers',
    component: CashRegistersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bookkeeping',
    component: BookkeepingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'charts',
    component: ChartsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'franchise',
    component: FranchiseComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'notes',
    component: NotesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'virtual-money',
    component: VirtualMoneyComponent,
    canActivate: [AuthGuard]
  },

  // otherwise redirect to Cashiers
  { path: '**', redirectTo: AppComponent.defaultUrl },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
