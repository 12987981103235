import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { Note } from '../../shared/types'
import { takeUntil } from 'rxjs/operators'
import { NotesService } from '../../services/notes.service'

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy {

  private onDestroy = new Subject()

  data: Note[]

  constructor(
    private notesService: NotesService,
  ) {}

  ngOnInit() {
    this.loadData()
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  private loadData(): void {
    this.notesService.getNotes()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.data = data
      })
  }

}
