import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { AuthenticationService } from '../services/authentication.service'
import { NzMessageService } from 'ng-zorro-antd'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private messageService: NzMessageService,
    private authenticationService: AuthenticationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      // 401 | 403 - Unauthorized or Forbidden
      if (err.status === 401 || err.status === 403) {
        this.authenticationService.resetUser()
        location.reload()
      }

      let msg = 'Unexpected error'
      if (err.error && err.error.msg) {
        // custom error message
        msg = err.error.msg
      } else if (err.error && err.error.errors) {
        // custom error message
        msg = err.error.errors[0].code
      } else if (err.statusText) {
        msg = err.statusText
      }
      this.messageService.error(msg)

      return throwError(err)
    }))
  }
}
