import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { BaseService } from './base.service'
import { IUserInfoFull } from '../shared/types'

@Injectable({ providedIn: 'root' })
export class FranchiseeService extends BaseService {

  constructor(
    http: HttpClient,
    authService: AuthenticationService
  ) {
    super(http, authService)
  }

  getInfo(): Observable<IUserInfoFull> {
    return this.post('francheeGetInfo.php')
      .pipe(map(data => data.user))
  }

  getHtmlInfo(): Observable<any> {
    return this.post('francheeInfo.php')
  }

  changePassword(
    data: { oldPasswd: string, newPasswd: string, gaKod?: string }
  ): Observable<any> {
    // const formData = new FormData()
    // Object.entries(data).forEach(([key, value]) => {
    //   formData.append(key, value as string)
    // })
    return this.post('setFrancheePasswd.php', {}, data)
  }
}
