import { Component, OnDestroy, OnInit } from '@angular/core'
import { IUserInfoFull } from '../../shared/types'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { FranchiseeService } from '../../services/franchisee.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { AuthenticationService } from '../../services/authentication.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  userInfo: IUserInfoFull

  form: FormGroup

  passwdRepeatError = false

  passwordChanged = false

  private onDestroy = new Subject()

  constructor(
    private franchiseeService: FranchiseeService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.franchiseeService.getInfo()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.userInfo = data
      })

    this.form = this.formBuilder.group({
      oldPasswd: ['', Validators.required],
      newPasswd: ['', Validators.required],
      newPasswdRepeat: ['', Validators.required],
      gaKod: ['']
    })
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  changePassword(): void {
    const { oldPasswd, newPasswd, newPasswdRepeat, gaKod } = this.form.value
    if (newPasswd === newPasswdRepeat) {
      const data: any = { oldPasswd, newPasswd }
      if (gaKod) {
        data.gaKod = gaKod
      }
      this.franchiseeService.changePassword(data)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(result => {
          if (result.status !== 'error') {
            this.onPasswordChangeSuccess()
          }
        })
    } else {
      this.passwdRepeatError = true
    }
  }

  onPasswordChangeSuccess(): void {
    this.passwordChanged = true
    setTimeout(() => {
      this.authService.logout()
        .subscribe(() => {
          this.router.navigate(['/login'])
        })
    }, 3000)
  }

}
