import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { BaseService } from './base.service'
import { Ask } from '../shared/types'

@Injectable({ providedIn: 'root' })
export class VirtualMoneyService extends BaseService {

  constructor(
    http: HttpClient,
    authService: AuthenticationService
  ) {
    super(http, authService)
  }

  getData(): Observable<Ask[]> {
    return this.post('francheeVirtualMoney.php')
      .pipe(map(data => data.asks))
  }

  ask(amount: number): Observable<Ask[]> {
    return this.post('francheeVirtualMoney.php?askForVirtualMoney=' + amount)
      .pipe(map(data => data.asks))
  }

  action(id: string, action: string): Observable<Ask[]> {
    return this.post(`francheeVirtualMoney.php?${action}=` + id)
      .pipe(map(data => data.asks))
  }
}
