import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { CashierService } from '../../services/cashier.service'
import { ICashier, IUserInfo } from '../../shared/types'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CashierEditComponent } from './cashier-edit/cashier-edit.component'
import { NzModalService } from 'ng-zorro-antd'
import { AuthenticationService } from '../../services/authentication.service'
import { CashRegisterService } from '../../services/cash-register.service'

@Component({
  selector: 'app-cashiers',
  templateUrl: './cashiers.component.html',
  styleUrls: ['./cashiers.component.scss']
})
export class CashiersComponent implements OnInit, OnDestroy {

  @ViewChild('cashierEdit', { static: true }) cashierEditForm: CashierEditComponent

  loading = true

  showOnlyActive = true

  // Table params
  pageIndex = 1
  pageSize = 10
  total = 0
  dataList: ICashier[]
  sortBy = null
  sortDesc: boolean

  filterString = ''
  filteredData: ICashier[]

  userInfo: IUserInfo

  private onDestroy = new Subject()

  constructor(
    private cashierService: CashierService,
    private cashRegisterService: CashRegisterService,
    private modalService: NzModalService,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.loadData()

    this.authService.currentUser.subscribe(user => {
      this.userInfo = user ? user.userInfo : null
    })
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  loadData(): void {
    this.loading = true
    this.filterString = ''

    this.cashierService.getAll(this.showOnlyActive, this.pageSize, this.pageIndex - 1, this.sortBy, this.sortDesc)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.dataList = data.list
        this.total = data.total
        this.updateTable()
        this.loading = false
      })
  }

  updateTable(): void {
    this.filteredData = this.filter(this.dataList)
  }

  newCashier(): void {
    this.cashierEditForm.onNew()
  }

  editCashier(cashier: ICashier): void {
    this.cashierEditForm.onEdit(cashier)
  }

  toggleCashierBlock(cashier: ICashier): void {
    this.confirm(() => {
      this.cashierService.updateCashierBlock(cashier.id, cashier.isActive ? 'block' : 'unblock')
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => {
          this.loadData()
        })
    })
  }

  switch2FA(cashier: ICashier): void {
    this.cashierService.setCashier2FA(cashier.id, !cashier.secFA)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.loadData()
      })
  }

  resetCashierPassword(cashier: ICashier): void {
    this.confirm(() => {
      this.cashierService.resetCashierPassword(cashier.id)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => {
          this.loadData()
        })
    })
  }

  onPageSizeChange(): void {
    // Wait till nzPageIndexChange run, then change pageIndex manually
    setTimeout(() => {
      this.pageIndex = 1
      this.loadData()
    })
  }

  onPageIndexChange(): void {
    // Do nothing, if onPageSizeChange loading data
    setTimeout(() => {
      if (!this.loading) {
        this.loadData()
      }
    })
  }

  setSort(sort: { key: string, value: string }): void {
    this.sortBy = sort.value !== null ? sort.key : null
    this.sortDesc = (sort.value === 'descend')

    this.pageIndex = 1
    this.loadData()
  }

  private confirm(onOk) {
    this.modalService.confirm({
      nzTitle: 'ARE YOU SURE?',
      nzCancelText: 'No',
      nzOkText: 'Yes',
      nzOnOk: () => onOk()
    })
  }

  private filter(data: ICashier[]) {
    if (!this.filterString) {
      return data.slice()
    } else {
      const filterString = this.filterString.toLowerCase()
      return data.filter(d => {
        return ([d.nickname, d.email])
          .join('|')
          .toLowerCase()
          .indexOf(filterString) !== -1
      })
    }
  }
}
