import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ICashier, ICashierShortInfo } from '../shared/types'
import { map } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { BaseService } from './base.service'

export interface ICashierData {
  nickname: string
  percentType: string
  percent: number
  percentMin?: number
  percentMax?: number
}

export interface ICashierDataFull extends ICashierData {
  email: string
  is2FA: boolean
}

@Injectable({ providedIn: 'root' })
export class CashierService extends BaseService {

  constructor(
    http: HttpClient,
    authService: AuthenticationService
  ) {
    super(http, authService)
  }

  getAll(
    activeOnly: boolean, pageSize: number, pageIndex: number, sortBy: string = null, sortDesc: boolean = false
  ): Observable<{ list: ICashier[], total: number }> {
    const params: any = {
      active: activeOnly ? 1 : 0,
      size: pageSize,
      page: pageIndex
    }

    if (sortBy) {
      params.sortField = sortBy
      params.sortOrder = sortDesc ? 'DESC' : 'ASC'
    }
    return this.post('francheeListCashiers.php', params)
      .pipe(map(data => {
        return {
          list: data.cashiers,
          total: data.totalItems
        }
      }))
  }

  getAllNames(): Observable<ICashierShortInfo[]> {
    return this.post('francheeListCashiers.php', { idOnly: 1 })
      .pipe(map(data => {
        return data.cashiers
      }))
  }

  createOne(data: ICashierDataFull): Observable<any> {
    return this.post('francheeCashier.php?add', null, data)
  }

  updateOne(id: string, data: ICashierData): Observable<any> {
    return this.post('francheeCashier.php', { edit: id }, data)
  }

  updateCashierBlock(id: string, action: string): Observable<any> {
    return this.post('francheeCashier.php', { id, action })
  }

  resetCashierPassword(id: string): Observable<any> {
    return this.post('francheeCashier.php', { resetPasswd: id })
  }

  setCashier2FA(id: string, value: boolean): Observable<any> {
    return this.post('francheeCashier.php', { id, secFA: value ? 'enable' : 'disable' })
  }

}
