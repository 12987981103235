import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { ChartDataService } from '../../services/chart-data.service'
import { ChartType, ScriptLoaderService } from 'angular-google-charts'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit, OnDestroy {

  columnNames = []

  ChartType = ChartType

  chartOptions: any = {
    axis: 'horizontal',
    orientation: 'horizontal',
    hAxis: {
      textStyle: {
        fontSize: 10
      }
    },
    vAxis: {
      gridlines: { color: 'none' }
    },
    series: {
      0: {
        targetAxisIndex: 0,
        color: '#00AAFF'
      },
      1: {
        targetAxisIndex: 0,
        color: '#00AAFF',
        label: 'sdfsfds'
      },
      2: {
        targetAxisIndex: 0
      },
      3: {
        targetAxisIndex: 1,
        pointSize: 5,
        type: 'line',
        color: '#CC3333'
      }
    },
    isStacked: true
  }

  chartData = null
  totals: [string, number][] = null

  periodOpts = []
  densityOpts = []

  period = '1D'
  density = '1h'

  private onDestroy = new Subject()

  constructor(
    private loaderService: ScriptLoaderService,
    private chartsService: ChartDataService,
    private translate: TranslateService
  ) {

    this.translate.get(['Volume', 'TX.buy', 'TX.sell', 'Profit', 'Number']).subscribe(words => {
      this.columnNames = ['Date', words['TX.buy'], words['TX.sell'], words.Profit, words.Number]
      this.chartOptions.vAxes = {
        0: { title: words.Volume + ' / ' + words.Profit },
        1: { title: words.Number },
      }
    })

  }

  ngOnInit() {
    this.loadData()
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  loadData(): void {
    this.chartsService.getChartData(this.period, this.density)
      .subscribe(({ data, totals, ranges }) => {

        // // fake data
        // data.forEach((item, i) =>  {
        //   const profit = Math.round(Math.random() * 20)
        //   const number = Math.round(Math.random() * 10)
        //   const buy = Math.round(Math.random() * 50)
        //   const sell = Math.round(Math.random() * 50)
        //   data[i] = { ...item, buy, sell, profit, number }
        // })
        // console.log('!', data)

        this.chartData = data.map(x => [
          x.date,
          x.buy,
          x.sell,
          x.profit,
          x.number
        ])
        this.totals = Object.entries(totals)

        this.periodOpts = ranges.period
        this.densityOpts = ranges.density
      })
  }
}
