import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { BaseService } from './base.service'
import { IFranchisor, IWithdrawData } from '../shared/types'
import { objToArr } from '../shared/utils'

@Injectable({ providedIn: 'root' })
export class DepositService extends BaseService {

  constructor(
    http: HttpClient,
    authService: AuthenticationService
  ) {
    super(http, authService)
  }

  getData(): Observable<[IFranchisor, IWithdrawData[]]> {
    return this.post('francheeDepoWithdraw.php')
      .pipe(map(data => {
        const withdrawData = objToArr(data.withdrawals) as IWithdrawData[]
        return [
          data.franchisor,
          withdrawData.reverse() // Need show from last to first
        ]
      }))
  }

  addWithdraw(fiatAmount: number, currency: string): Observable<any> {
    return this.post('francheeDepoWithdraw.php', { fiatAmount, currency })
  }

  deleteWithdraw(id: string): Observable<any> {
    return this.post('francheeDepoWithdraw.php', {
      delete: id,
    })
  }
}
