import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { AuthenticationService } from './authentication.service'
import { BaseService } from './base.service'
import { ICashRegister, ICashRegisterData, ICashRegisterRaw } from '../shared/types'
import { map } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class CashRegisterService extends BaseService {

  constructor(
    http: HttpClient,
    authService: AuthenticationService
  ) {
    super(http, authService)
  }

  /**
   * Get all cash registers
   */
  getAll(): Observable<ICashRegister[]> {
    return this.crGet('money-boxes')
      .pipe(map(data => {
        return data.items.map((item: ICashRegisterRaw) => {
          // Need only PLN balance
          let balanceString = ''
          for (const balanceItem of item.balances) {
            if (balanceItem.currency.toLowerCase() === 'pln') {
              balanceString = balanceItem.amount + ' ' + 'PLN'
            }
          }

          return { ...item, balanceString }
        })
      }))
  }

  createOne(data: ICashRegisterData): Observable<any> {
    return this.crPut('money-boxes', {}, data)
  }

  updateOne(id: number, data: ICashRegisterData): Observable<any> {
    return this.crPut('money-boxes', {}, { id, ...data })
  }

  deleteOne(id: number): Observable<any> {
    return this.crDelete(`money-boxes/${id}`)
  }
}
