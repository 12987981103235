import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { BehaviorSubject, Observable } from 'rxjs'

const { WS_URL } = environment

export interface IWsMsg {
  type: string
  data: string
}

@Injectable({ providedIn: 'root' })
export class WebSocketService {

  public wsMessages: Observable<IWsMsg>
  private wsMessagesSubject: BehaviorSubject<IWsMsg>

  private webSocket: WebSocket
  private webSocketOpened = false

  private webSocketReconnectTimeout

  constructor() {
    this.wsMessagesSubject = new BehaviorSubject<IWsMsg>(null)
    this.wsMessages = this.wsMessagesSubject.asObservable()
  }

  public get isWsConnected(): boolean {
    return this.webSocketOpened
  }

  connect() {
    const loginToken = sessionStorage.getItem('loginToken')
    if (!loginToken) {
      console.error(`Unexpected case - loginToken doesn't exist`)
      return
    }

    this.webSocket = new WebSocket(`${WS_URL}?wsID=${loginToken}`)

    this.webSocket.onopen = () => {
      console.log('WebSocked connection opened')
      this.webSocketOpened = true
    }

    this.webSocket.onclose = () => {
      console.log('WebSocket closed. Reconnect after 3s')
      this.webSocket = null
      this.webSocketOpened = false

      this.webSocketReconnectTimeout = setTimeout(() => {
       this.connect()
      }, 3000)
    }

    this.webSocket.onmessage = e => {
      try {
        const data = JSON.parse(e.data)
        this.wsMessagesSubject.next(data)
      } catch (e) {
        throw Error(`Can't parse WebSocket message data`)
      }
    }
  }

  close() {
    clearTimeout(this.webSocketReconnectTimeout)
    if (this.webSocket) {
      this.webSocket.onclose = null
      this.webSocket.close()
      this.webSocket = null
      this.webSocketOpened = false
    }
  }
}
