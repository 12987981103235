import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { DepositService } from '../../services/deposit.service'
import { takeUntil } from 'rxjs/operators'
import { IFranchisor, IUserInfo, IWithdrawData } from '../../shared/types'
import { AuthenticationService } from '../../services/authentication.service'

/**
 * Deposit & Withdraw
 */
@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit, OnDestroy {

  franchisor: IFranchisor
  withdrawData: IWithdrawData[] = []

  withdrawAmount: number | string = ''

  userInfo: IUserInfo

  private onDestroy = new Subject()

  constructor(
    private depositService: DepositService,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.loadData()

    this.authService.currentUser.subscribe(user => {
      this.userInfo = user ? user.userInfo : null
    })
  }

  ngOnDestroy(): void {
    this.onDestroy.next()
    this.onDestroy.complete()
  }

  deleteWithdraw(id: string): void {
    this.depositService.deleteWithdraw(id)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.loadData()
      })
  }

  sendWithdraw(): void {
    this.depositService.addWithdraw(this.withdrawAmount as number, this.userInfo.fiat)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.withdrawAmount = ''
        this.loadData()
      })
  }

  private loadData(): void {
    this.depositService.getData()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        [this.franchisor, this.withdrawData] = data
      })
  }
}
