import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { AuthenticationService } from '../services/authentication.service'
import { AppComponent } from '../app.component'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue

    if (currentUser) {
      // authorised so return true
      return true
    }

    // not logged in so redirect to login page with the return url
    const queryParams: any = {}
    if (state.url !== AppComponent.defaultUrl) {
      queryParams.returnUrl = state.url
    }
    this.router.navigate(['/login'], { queryParams })
    return false
  }

}
